
  import { defineComponent, ref, toRefs, getCurrentInstance } from "vue"
  import { ResourceObject } from "@/models/jsonapi"
  import { Household } from "@/models/households"

  interface Props {
    household: ResourceObject<string, Household>
  }

  export default defineComponent({
    props:{
      household: {
        type: Object,
        required: true
      }
    },
    setup(props: Props, { emit }) {
      const root = getCurrentInstance().proxy
      const formHousehold = ref({...props.household.attributes})

      const updateForm = async () => {
        if (!props.household.id) throw Error("Fehler: Haushalt ohne ID")
        await root.$store.dispatch('households/edit', { id: props.household.id, body: { attributes: formHousehold.value } })
        emit('household-updated')
      }

      return {
        formHousehold,
        updateForm
      }
    }
  })
