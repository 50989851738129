
  import { defineComponent } from "vue"
  import IconsBar from "./IconsBar.vue"
  import { ResourceObject } from "@/models/jsonapi";
  import { CareReceiver } from "@/models/care_receivers";

  interface Props {
    careReceiver: ResourceObject<string, CareReceiver>
  }

  export default defineComponent({
    name: 'IconsBarCareReceiver',
    components: {
      IconsBar
    },
    props: {
      careReceiver: {
        type: Object,
        required: true
      }
    },
    setup(props: Props) {
      const createIcons = () => {
        const icons: any[] = []
        if (props.careReceiver.attributes?.gender === 'Mann') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'mars', class: 'text-muted fa-2x', title: 'männlich' }] })
        } else if (props.careReceiver.attributes?.gender === 'Frau') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'venus', class: 'text-muted fa-2x', title: 'weiblich' }] })
        } else {
          icons.push({ stack: [{ prefix: 'fas', icon: 'question', class: 'lighter', title: 'Geschlecht' }] })
        }

        if (props.careReceiver.attributes?.careLevel === '1') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'thermometer-empty', class: 'text-muted fa-2x', title: 'Pflegegrad 1' }] })
        } else if (props.careReceiver.attributes?.careLevel === '2') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'thermometer-quarter', class: 'text-muted fa-2x', title: 'Pflegegrad 2' }] })
        } else if (props.careReceiver.attributes?.careLevel === '3') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'thermometer-half', class: 'text-muted fa-2x', title: 'Pflegegrad 3' }] })
        } else if (props.careReceiver.attributes?.careLevel === '4') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'thermometer-three-quarters', class: 'text-muted fa-2x', title: 'Pflegegrad 4' }] })
        } else if (props.careReceiver.attributes?.careLevel === '5') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'thermometer-full', class: 'text-muted fa-2x', title: 'Pflegegrad 5' }] })
        } else {
          icons.push({ stack: [{ prefix: 'fas', icon: 'question', class: 'lighter', title: 'Pflegegrad' }] })
        }

        if (props.careReceiver.attributes?.roomMate === 'alleinlebend') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'user', class: 'text-muted', title: 'Wohnsituation: alleinlebend' }] })
        } else if (props.careReceiver.attributes?.roomMate === 'Wohngemeinschaft') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'user-plus', class: 'text-muted', title: 'Wohnsituation: Wohngemeinschaft' }] })
        } else if (props.careReceiver.attributes?.roomMate === 'Ehepartner') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'user-plus', class: 'text-muted', title: 'Wohnsituation: Mit Ehepartner' }] })
        } else if (props.careReceiver.attributes?.roomMate === 'Angehöriger') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'user-plus', class: 'text-muted', title: 'Wohnsituation: Mit Angehörigem' }] })
        } else {
          icons.push({ stack: [{ prefix: 'fas', icon: 'question', class: 'lighter', title: 'Wohnsituation' }] })
        }

        if (props.careReceiver.attributes?.internet === 'vorhanden') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'wifi', class: 'text-muted', title: 'Internet vorhanden' }] })
        } else if (props.careReceiver.attributes?.internet === 'kann eingerichtet werden') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'wifi', class: 'text-muted', title: 'Internet kann eingerichtet werden' }] })
        } else if (props.careReceiver.attributes?.internet === 'Nein, nicht möglich') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'wifi-slash', class: 'text-muted', title: 'kein Internet' }] })
        } else {
          icons.push({ stack: [{ prefix: 'fas', icon: 'question', class: 'lighter', title: 'Internet' }] })
        }

        const walkAbility = props.careReceiver.attributes?.walk
        if (walkAbility && ['uneingeschränkt', "teilweise eingeschränkt", "selbstständig", "am Hilfsmittel"].indexOf(walkAbility)>-1) {
          icons.push({ stack: [{ prefix: 'fas', icon: 'walking', class: 'text-muted', title: `Mobilität: ${walkAbility}` }] })
        } else if (walkAbility && ["eingeschränkt", "nicht möglich", "am Hilfsm. mit Unterstützung", "nicht möglich"].indexOf(walkAbility)>-1) {
          icons.push({ stack: [{ prefix: 'fas', icon: 'walking', class: 'text-muted', title: `Mobilität: ${walkAbility}` }, { prefix: 'far', icon: 'ban', class: 'text-muted', title: 'Mobilität' }] })
        } else {
          icons.push({ stack: [{ prefix: 'fas', icon: 'question', class: 'lighter', title: 'Mobilität' }] })
        }

        if (props.careReceiver.attributes?.ctResidenceType === 'Einfamilienhaus') {
          icons.push({ stack: [{ prefix: 'far', icon: 'home-alt', class: 'text-muted', title: 'Einfamilienhaus' }] })
        } else if (props.careReceiver.attributes?.ctResidenceType === 'Wohnung') {
          icons.push({ stack: [{ prefix: 'far', icon: 'home-alt', class: 'text-muted', title: 'Wohnung' }, { prefix: 'far', icon: 'ban', class: 'text-muted', title: 'Wohnung' }] })
        } else if (props.careReceiver.attributes?.ctResidenceType === 'Mehrfamilienhaus') {
          icons.push({ stack: [{ prefix: 'far', icon: 'home-alt', class: 'text-muted', title: 'Mehrfamilienhaus' }, { prefix: 'far', icon: 'ban', class: 'text-muted', title: 'Mehrfamilienhaus' }] })
        } else if (props.careReceiver.attributes?.ctResidenceType === 'Sonstiges') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'ellipsis-h', class: 'text-muted', title: 'Sonstiges' }] })
        } else {
          icons.push({ stack: [{ prefix: 'fas', icon: 'question', class: 'lighter', title: 'Residence Type' }] })
        }

        if (props.careReceiver.attributes?.ctResidence === 'Dorf') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'city', class: 'text-muted fa-stack-1x', title: 'Dorf' }, { prefix: 'far', icon: 'ban', class: 'text-muted', title: 'Residence' }] })
        } else if (props.careReceiver.attributes?.ctResidence === 'Kleinstadt') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'city', class: 'text-muted', title: 'Kleinstadt' }] })
        } else if (props.careReceiver.attributes?.ctResidence === 'Großstadt') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'city', class: 'text-muted', title: 'Großstadt' }] })
        } else {
          icons.push({ stack: [{ prefix: 'fas', icon: 'question', class: 'lighter', title: 'Residence' }] })
        }

        if (props.careReceiver.attributes?.challengingBehaviour === 'Ja') {
          icons.push({ stack: [{ prefix: 'far', icon: 'angry', class: 'text-muted fa-2x', title: 'herausfordernde Verhaltensweisen' }] })
        } else if (props.careReceiver.attributes?.challengingBehaviour === 'Nein') {
          icons.push({ stack: [{ prefix: 'far', icon: 'smile', class: 'text-muted fa-2x', title: 'keine herausfordernde Verhaltensweisen' }] })
        } else {
          icons.push({ stack: [{ prefix: 'fas', icon: 'question', class: 'lighter', title: 'Verhaltensweisen' }] })
        }

        return icons
      }

      return {
        createIcons
      }
    }
  })
