import { Field, Dependency } from "@/models/care_receivers";

const isBlank = (val: any) => {
  return Array.isArray(val) ? val.length === 0 : !val
}

const dependencyMet = (dependency: Dependency, form: { [key:string]: any }) => {
  return form[dependency.key] == dependency.value || dependency.value.includes(form[dependency.key])
}

export const getRequiredFields = (fields: Field[], form: { [key:string]: any }) => {
  return fields
    .filter((f) => f.required)
    .filter((f) => !f.dependency || dependencyMet(f.dependency, form))
}

export const getNonRequiredFields = (fields: Field[], form: { [key:string]: any }) => {
  return fields
    .filter((f) => !f.required)
    .filter((f) => !f.dependency || dependencyMet(f.dependency, form))
}

export const getMissingFields = (fields: Field[], form: { [key:string]: any }) => {
  return getRequiredFields(fields, form).filter(f => isBlank(form[f.key]))
}
