
  import { defineComponent, ref, toRefs, watch, getCurrentInstance } from "vue"
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"
  import { Address } from "@/models/addresses";
  import { Phone as PhoneModel } from "@/models/phones";
  import { Email as EmailModel } from "@/models/emails";
  import { useModelWrapper } from "@/utils/modelWrapper";

  interface Props {
    addressId?: string,
    address: Address
  }

  export default defineComponent({
    components: {
      SchemaForm,
    },
    props:{
      addressId: {
        type: String
      },
      address: {
        type: Object,
        default: () => ({ additional: '', city: '', country: '', street: '', streetNumber: '', zipCode: '', emails: [], phones: [] })
      }
    },
    setup(props: Props, { emit }) {
      const root = getCurrentInstance().proxy
      const formAddress = ref({
        firstName: props.address.firstName,
        lastName: props.address.lastName,
        gender: props.address.gender,
        street: props.address.street,
        houseNumber: props.address.houseNumber,
        additional: props.address.additional,
        zipCode: props.address.zipCode,
        city: props.address.city,
        country: props.address.country,
        emails: props.address.emails.map(email => ({...email})),
        phones: props.address.phones.map(phone => ({...phone}))
      })

      const formSchema = {
        $schema: "https://json-schema.org/draft/2019-09/schema",
        $id: "https://crm.pflegehilfe-senioren.de/address-model.json",
        type: "object",
        properties: {
          firstName: { type: "string", title: "Vorname" },
          lastName: { type: "string", title: "Nachname" },
          gender: { type: "string", title: "Geschlecht", enum: ['f', 'm', 'd'] },
          street: { type: "string", title: "Strasse" },
          houseNumber: { type: "string", title: "Hausnummer" },
          additional: { type: "string", title: "Adresszusatz" },
          zipCode: { type: "string", title: "PLZ" },
          city: { type: "string", title: "Ort" },
          country: { type: "string", title: "Land" },
          phones: {
            title: "Phones",
            type: "array",
            items: {
              type: "object",
              properties: {
                number: { type: "string", title: "Telefonnummer" },
                name: { type: "string", title: "Bezeichnung" },
                default: { type: "boolean", title: "Default Nummer" }
              }
            }
          },
          emails: {
            title: "Emails",
            type: "array",
            items: {
              type: "object",
              properties: {
                email: { type: "string", title: "E-Mail-Adresse", format: "email" },
                name: { type: "string", title: "Bezeichnung" },
                default: { type: "boolean", title: "Default E-Mail-Adresse" }
              }
            }
          }
        },
      }

      const uiSchema = {
        $schema: "https://json-schema.org/draft/2019-09/schema",
        $id: "https://crm.pflegehilfe-senioren.de/address-view.json",
        $vocabulary: {
          "https://json-schema.org/draft/2019-09/vocab/core": true,
          "https://crm.pflegehilfe-senioren.de/ui-vocab": true
        },
        "ui:type": "form",
        "ui:widget": "b-row",
        type: "object",
        properties: {
          gender: {
            $ref: "https://crm.pflegehilfe-senioren.de/address-model.json#/properties/gender",
            "ui:label": "",
            "ui:widget": "b-form-radio-group",
            "ui:classNames": ["col-12"],
            "ui:options": {
              options: [
                {value: "m", text: "Mann"},
                {value: "f", text: "Frau"},
                // {value: "d", text: "Divers"},
              ]
            },
          },
          firstName: {
            $ref: "https://crm.pflegehilfe-senioren.de/address-model.json#/properties/firstName",
            "ui:classNames": ["col-6"],
            "ui:label": "Vorname",
          },
          lastName: {
            $ref: "https://crm.pflegehilfe-senioren.de/address-model.json#/properties/lastName",
            "ui:classNames": ["col-6"],
            "ui:label": "Nachname"
          },
          street: {
            $ref: "https://crm.pflegehilfe-senioren.de/address-model.json#/properties/street",
            "ui:classNames": ["col-12 col-md-9 col-lg-10"],
            "ui:label": "Strasse"
          },
          houseNumber: {
            $ref: "https://crm.pflegehilfe-senioren.de/address-model.json#/properties/houseNumber",
            "ui:classNames": ["col-12 col-md-3 col-lg-2"],
            "ui:label": "Hausnummer"
          },
          additional: {
            $ref: "https://crm.pflegehilfe-senioren.de/address-model.json#/properties/additional",
            "ui:classNames": ["col-12"],
            "ui:label": "Adresszusatz"
          },
          zipCode: {
            $ref: "https://crm.pflegehilfe-senioren.de/address-model.json#/properties/zipCode",
            "ui:classNames": ["col-12 col-md-4"],
            "ui:label": "PLZ"
          },
          city: {
            $ref: "https://crm.pflegehilfe-senioren.de/address-model.json#/properties/city",
            "ui:classNames": ["col-12 col-md-4"],
            "ui:label": "Ort"
          },
          country: {
            $ref: "https://crm.pflegehilfe-senioren.de/address-model.json#/properties/country",
            "ui:classNames": ["col-12 col-md-4"],
            "ui:label": "Land"
          },
          phones: {
            "ui:type": "collection",
            "ui:label": "Phones",
            $ref: "https://crm.pflegehilfe-senioren.de/address-model.json#/properties/phones",
            items: {
              type: "object",
              "ui:type": "group",
              properties: {
                name: {
                  $ref: "https://crm.pflegehilfe-senioren.de/address-model.json#/properties/phones/items/properties/name",
                  "ui:classNames": ["col-xl-5"],
                  "ui:label": "Bezeichnung"
                },
                number: {
                  $ref: "https://crm.pflegehilfe-senioren.de/address-model.json#/properties/phones/items/properties/number",
                  "ui:classNames": ["col-sm-10 col-xl-5"],
                  "ui:label": "Telefonnummer"
                },
                default: {
                  $ref: "https://crm.pflegehilfe-senioren.de/address-model.json#/properties/phones/items/properties/default",
                  "ui:label": "Default Nummer",
                  "ui:type": "checkbox",
                  "ui:classNames": ["col-sm-2"],
                  "ui:options": {
                    disabled: false,
                    setter: (setElement, scope, value) => {
                      const phonesSize = formAddress.value.phones.length
                      const splitScope = scope.split('/')
                      const currentIndex = splitScope[splitScope.length - 2]
                      for (let i = 0; i < phonesSize; i++) {
                        if (i !== currentIndex && value) {
                          setElement(`/phones/${i}/default`, false)
                        }
                      }
                      setElement(scope, value)
                    }
                  }
                },
              }
            }
          },
          emails: {
            "ui:type": "collection",
            "ui:label": "Emails",
            $ref: "https://crm.pflegehilfe-senioren.de/address-model.json#/properties/emails",
            items: {
              type: "object",
              "ui:type": "group",
              properties: {
                name: {
                  $ref: "https://crm.pflegehilfe-senioren.de/address-model.json#/properties/emails/items/properties/name",
                  "ui:classNames": ["col-xl-5"],
                  "ui:label": "Bezeichnung"
                },
                email: {
                  $ref: "https://crm.pflegehilfe-senioren.de/address-model.json#/properties/emails/items/properties/email",
                  "ui:classNames": ["col-sm-10 col-xl-5"],
                  "ui:label": "E-Mail-Adresse"
                },
                default: {
                  $ref: "https://crm.pflegehilfe-senioren.de/address-model.json#/properties/emails/items/properties/default",
                  "ui:label": "Default E-Mail-Adresse",
                  "ui:type": "checkbox",
                  "ui:classNames": ["col-sm-2"],
                  "ui:options": {
                    disabled: false,
                    setter: (setElement, scope, value) => {
                      const emailsSize = formAddress.value.emails.length
                      const splitScope = scope.split('/')
                      const currentIndex = splitScope[splitScope.length - 2]
                      for (let i = 0; i < emailsSize; i++) {
                        if (i !== currentIndex && value) {
                          setElement(`/emails/${i}/default`, false)
                        }
                      }
                      setElement(scope, value)
                    }
                  }
                },
              }
            }
          },
        }
      }

      const setDefault = (index: number, event: boolean, elements: PhoneModel[]|EmailModel[]) => {
        if (!event) {
          elements[index].default = false
        } else {
          // If is default, other should not be default anymore
          elements.forEach((element, elementIndex) => element.default = index === elementIndex)
        }
      }

      const updatedForm = async (formValue) => {
        formAddress.value = formValue
      }

      const updateForm = async () => {
        if (props.addressId) {
          await root.$store.dispatch('addresses/edit', { id: props.addressId, body: { attributes: formAddress.value } })
          emit('address-updated')
        }
      }

      return {
        formSchema,
        uiSchema,
        formAddress,
        setDefault,
        updateForm,
        updatedForm,
      }
    }
  })
