
  import { Log } from "@/models/logs"
  import moment from 'moment'
  import { defineComponent, ref, reactive, computed } from "vue"
  import { useModelWrapper } from "@/utils/modelWrapper";
  import { getConfirmationModal } from "@/plugins/confirmation"
  import LogsRepository from "@/api/logsRepository"

  interface Props {
    logs: Log[],
    parentType: string,
    parentId: string
  }

  export default defineComponent({
    props: {
      logs: {
        type: Array,
        default: () => []
      },
      parentType: {
        type: String,
        required: true
      },
      parentId: {
        type: String,
        required: true
      }
    },
    setup(props: Props, { emit }) {
      const editModal = ref()
      const logId = ref<string>()
      const note = ref("")

      const formatDate = (date: string) => moment(date).locale('de').format('lll')

      const addLog = () => {
        logId.value = undefined
        note.value = ""
        editModal.value.show()
      }

      const editLog = (id: string) => {
        logId.value = id
        note.value = props.logs.find((log: Log) => log.id === id)?.note ?? ""
        editModal.value.show()
      }

      const updateLog = async () => {
        let newLogs = props.logs
        if (!logId.value) {
          // Add a new log
          newLogs.push({ note: note.value })
        } else {
          const editedLog = newLogs.find(log => log.id === logId.value)
          if (editedLog) {
            editedLog.note = note.value
          }
        }
        await LogsRepository.update(props.parentType, props.parentId, newLogs)
        emit("update")
      }

      const confirmationModal = getConfirmationModal()
      const launchDeleteConfirm = (id: string) => {
        confirmationModal.confirmation("Möchtest du diesen Log wirklich löschen?", async () => {
          await LogsRepository.delete(props.parentType, props.parentId, id)
          emit("update")
        })
      }

      const sortedLogs = computed(() => {
        return props.logs.filter((log) => {
          return log.type === undefined || log.type !== 'transaction'
        }).sort((a, b) => {
          if (a.createdAt && b.createdAt) {
            return Date.parse(b.createdAt) - Date.parse(a.createdAt)
          } else { return 0 }
        })
      })

      return {
        editModal,
        logId,
        note,
        formatDate,
        addLog,
        editLog,
        updateLog,
        launchDeleteConfirm,
        sortedLogs
      }
    }
  })
