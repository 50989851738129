
  import { defineComponent, ref } from "vue"
  import Address from "./Address.vue"
  import Phone from "./Phone.vue"
  import AddressForm from "./AddressForm.vue"

  export default defineComponent({
    name: 'AddressCard',
    components: {
      Address,
      Phone,
      AddressForm
    },
    props: {
      address: Object,
      contactType: String,
      url: String,
    },
    setup(props, { emit }) {
      const editModal = ref()
      const modalVisible = ref(false)
      const showModal = () => {
        syncEditContact()
        editModal.value.show()
      }

      const editContact = ref()
      const syncEditContact = () => editContact.value = {...props.address?.attributes}
      syncEditContact()

      const onAddressUpdate = () =>  {
        editModal.value.hide()
        emit("refresh")
      }

      return {
        editContact,
        editModal,
        modalVisible,
        showModal,
        onAddressUpdate
      }
    }
  })
