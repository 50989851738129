
  import { defineComponent, computed, getCurrentInstance } from "vue"
  import { taskTitleColumn, taskDueAtColumn, taskNotesColumn, taskFrozenColumn, taskPriorityColumn, initializeModal } from "@/view_scripts/dashboardUtils"
  import TaskEditModal from "@/components/shared/modals/TaskEditModal.vue"
  import { getKeycloak } from "@/plugins/keycloak"
  // import { Task } from "@/models/tasks"
  // import {ResourceObject} from '@/models/jsonapi'

  interface Props { taskable: any }

  export default defineComponent({
    props: { taskable: { type: Object } },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const keycloakRef = getKeycloak()
      const role = keycloakRef.value.tokenParsed?.resource_access?.['pflegehilfe-senioren-platform']['roles'][0]
      const tasks = computed(() => root.$store.state.tasks.data )
      const totalItems = computed(() => root.$store.state.tasks.pagination.totalItems)
      const refreshData = () => {
        const queryParams = { 'filter[taskable_id]': props.taskable.id }
        root.$store.dispatch('tasks/load', queryParams )
      }
      const { modalType, modalProps, addReminderAction, addDestroyTaskAction } = initializeModal(root, refreshData)
      const columns = [
        taskTitleColumn,
        taskDueAtColumn,
        taskNotesColumn,
        taskPriorityColumn,
        taskFrozenColumn
      ]
      addReminderAction(columns)
      addDestroyTaskAction(columns)

      const editDone = (reload?: boolean) => {
        modalProps.value = { visible: false }
        refreshData()
      }

      const addTask = () => {
        // showTaskEditModal({item: {id:'new', type:'tasks',attributes:{}}})
        const taskable = { taskableId: props.taskable.id, taskableType: props.taskable.type }
        const atId = `${role?.substring(0,1)}_${props.taskable?.type?.split('_').map((e)=>e.substring(0,1)).join('')}_man`
        const task:any = {id:'new', type:'tasks', attributes: { ...taskable, actionTypeId: atId }}
        modalType.value = TaskEditModal
        modalProps.value = {
          task: task,
          editMode: false,
          visible: true,
          initialNote: task.attributes.note ?? '',
          initialDueAt: task.attributes.dueAt ?? '',
          initialVisibleAt: task.attributes.visibleAt ?? '',
          initialPriority: task.attributes.priority ?? false,
          initialFrozen: task.attributes.frozen ?? false,
          taskableId: props.taskable.id,
          taskableType: props.taskable.type,
        }
      }

      return {
        totalItems,
        tasks,
        columns,
        modalType,
        modalProps,
        editDone,
        addTask
      }
    }
  })
