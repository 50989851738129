
  import { defineComponent, ref, computed, reactive, getCurrentInstance } from "vue"
  import HouseholdForm from "@/components/shared/HouseholdForm.vue"
  import HouseholdKeyData from "@/components/shared/HouseholdKeyData.vue";
  import AddressCard from "@/components/shared/AddressCard.vue";
  import IconsBarCareReceiver from "@/components/shared/IconsBarCareReceiver.vue";
  import IconsBarCareGiverRequirements from "@/components/shared/IconsBarCareGiverRequirements.vue";
  import Logs from "@/components/shared/Logs.vue";
  import CareReceiverProgressBar from "@/components/shared/CareReceiverProgressBar.vue";
  import Tickets from "@/components/shared/Tickets.vue"
  import Tasks from "@/components/shared/Tasks.vue"
  import { Household } from "@/models/households";
  import { CareReceiver } from "@/models/care_receivers";
  import { Lead } from "@/models/leads";
  import { Log } from "@/models/logs"
  import { Posting } from "@/models/postings"
  import { Contract } from "@/models/contracts";
  import { Ticket } from "@/models/tickets";
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"
  import { resourceUrl, fullName } from "@/utils/dataExtractors"
  import { shortDate } from "@/utils/helper"
  import { CollectionResourceDoc, ResourceObject, RelationshipsWithData, ResourceIdentifierObject } from '@/models/jsonapi'
  import { CrPage, CrPages } from "@/models/care_receivers";
  import PostingFormEdit from "@/components/shared/PostingFormEdit.vue"
  import TicketEdit from "@/components/shared/TicketEdit.vue"
  import CrPanel from "@/components/shared/CrPanel.vue"
  import { getMissingFields } from "@/utils/careReceiverHelper"
  import { newTicketAction } from "@/view_scripts/title_action_new_ticket";
  import { confirm, prompt } from '@/utils/interactionModals'

  interface Props {
    id: string
  }

  export default defineComponent({
    components: {
      AddressCard,
      IconsBarCareReceiver,
      IconsBarCareGiverRequirements,
      Logs,
      HouseholdForm,
      CareReceiverProgressBar,
      PostingFormEdit,
      TicketEdit,
      Tickets,
      CrPanel,
      HouseholdKeyData,
      Tasks
    },
    props: {
      id: {
        type: String,
        required: true
      }
    },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const showLoading = ref<boolean>(false)
      const editModal = ref()
      const newPostingModal = ref()
      const newTicketModal = ref()

      const householdResponse = computed(() => root.$store.state.households.elementResponse)
      const household = computed<ResourceObject<string, Household>>(() => root.$store.state.households.element)
      const title = computed(() => household.value ? household.value.attributes?.designation : '' )
      const posting = computed<ResourceObject<string, Posting> | undefined>(() => household.value.attributes?.posting)
      const careReceivers = computed<ResourceObject<string, CareReceiver>[]>(() => root.$store.state.care_receivers.data)
      const lead = computed<ResourceObject<string, Lead>>(() => root.$store.state.leads.element)
      const contracts = computed<ResourceObject<string, Contract>[]>(() => root.$store.state.contracts.data)
      const tickets = computed<ResourceObject<string, Ticket>[]>(() => root.$store.state.tickets.data)
      const ticketIds = computed<string[]>(() => householdResponse.value ? extractRelationshipIds(householdResponse.value, 'tickets') : [])
      const careReceiverForm = computed<CrPage[]>(() => root.$store.state.care_receivers.pages ?? [])
      const colsXl = computed(() => {
        if (careReceivers?.value?.length == 2) {
          return 3
        } else {return 4}
      })
      const contactAddress = computed(() => {
        if (lead.value && careReceivers.value && household.value) {
          const crAddresses = careReceivers.value.map((e) => e.attributes?.address)
          crAddresses.push(lead.value.attributes?.address)
          return crAddresses.filter((a) => a.id === household.value.attributes?.contactAddressId)[0]
        } else {return null}
      })

      const refreshData = async () => {
        root.$store.dispatch('tasks/load', { 'filter[taskable_id]': props.id, include: 'action_type' } )
        await root.$store.dispatch('households/get', { id: props.id, queryParams: { include: 'posting' } })

        const leadRelationship = household.value?.relationships?.lead as RelationshipsWithData
        const leadRelationshipData = leadRelationship?.data as ResourceIdentifierObject
        const contractsRelationship = household.value?.relationships?.contracts as RelationshipsWithData
        const contractsRelationshipData = contractsRelationship?.data as ResourceIdentifierObject[]
        const ticketsRelationship = household.value?.relationships?.tickets as RelationshipsWithData
        const ticketsRelationshipData = ticketsRelationship?.data as ResourceIdentifierObject[]

        root.$store.dispatch('leads/get', { id: leadRelationshipData?.id, queryParams: { include: 'address,seller' } })
        const contractsQuery = {
          'filter[id]': contractsRelationshipData.map(c => c.id).join(','),
          include: 'agency,segments',
          'page[size]': 100
        }
        root.$store.dispatch('contracts/load', contractsQuery)
        const ticketsQuery = { 'filter[id]': ticketsRelationshipData.map(c => c.id).join(',') }
        root.$store.dispatch('tickets/load', ticketsQuery)

        root.$store.dispatch('care_receivers/get_pages')

        const crQuery = {'filter[household_id]': props.id, include: 'address,file_meta_data'}
        root.$store.dispatch('care_receivers/load', crQuery)
      }

      refreshData()

      const householdPosting = reactive({
        agencies: [],
        available_nationalities: [],
        posting: {},
        agencyScopes: []
      })

      const getPostingData = async () => {
        await root.$store.dispatch('households/get_new_posting_data', props.id)
        const postingData = root.$store.state.households.newPostingData
        householdPosting.agencyScopes = postingData.agencyScopes
        householdPosting.agencies = postingData.agencies
        householdPosting.available_nationalities = postingData.available_nationalities
        householdPosting.posting = postingData.posting
      }

      const allFormularFilled = computed(() => {
        if (!careReceivers.value || careReceivers.value.length === 0) return true
        const allFields = careReceiverForm.value.flatMap(page => page.fields)
        for (const careReceiver of careReceivers.value) {
          if (!careReceiver.attributes) return false
          const missingFields = getMissingFields(allFields, careReceiver.attributes)
          if (missingFields.length > 0) {
            return false
          }
        }
        return true
      })

      const allFmdsPresent = computed(() => {
        if (careReceivers.value?.length > 0) {
          let count = 0
          const fmds = careReceivers.value.forEach((cr) => {
            const fmd = cr.relationships?.file_meta_data as RelationshipsWithData
            if (fmd?.data) count += 1
          })
          return count == careReceivers.value.length
        } else {return false}
      })

      const titleActions = computed(() => {
        const postingPossible = allFormularFilled.value && posting.value == undefined && allFmdsPresent.value
        let tooltip
        if (postingPossible) {
          tooltip = 'Posting erstellen'
        } else if (posting.value != undefined) {
          tooltip = 'Posting bereits vorhanden'
        } else if (!allFormularFilled.value) {
          tooltip = 'Posting nicht möglich, PB(s) unvollständig!'
        } else if (!allFmdsPresent.value) {
          tooltip = 'Mindestens ein EB-Pdf fehlt'
        }
        const actions:[{[key: string]: any}] = [
          {
            title: 'Bearbeiten',
            icon: 'pencil-alt',
            tooltip: 'Haushalt bearbeiten',
            onClick: () => editModal.value.show()
          }
        ]
        actions.push({
          title: 'Posting erstellen',
          icon: postingPossible ? 'bullhorn': 'exclamation-triangle',
          tooltip: tooltip,
          // tooltip: postingPossible ? 'Posting erstellen': (allFormularFilled.value ? 'Posting bereits vorhanden' : 'Posting nicht möglich, PB(s) unvollständig!'),
          onClick: async () => { if (postingPossible) {
            await getPostingData()
            newPostingModal.value.show()
          } }
        })
        actions.push( newTicketAction(newTicketModal) )
        actions.push( {
          title: 'Bewertungsmail',
          icon: ['far', 'smile'],
          tooltip: 'Bewertung per Mail Anfragen',
          onClick: async () => {
            const leadId = (household.value.relationships?.lead as any)?.data?.id
            if (!household.value.attributes?.contactAddressId) {
              prompt(root, 'Kontaktadresse wurde nicht festgelegt. Bitte im Kontextmenu des EB die Lead- bzw. Pflegebedürftigen-Adresse als Kontaktadresse des Haushalt einstellen und sicher stellen, dass die Email vorhanden und richtig ist.')
            } else {
              const email = contactAddress.value.attributes.emails.filter((e) => e.default)[0]?.email
              const msg = `Die Bewertungsmail wird gesandt an: ${email}`
              const confirmation = await confirm(root, msg)
              if (confirmation && leadId && email) {
                const data = {mail_data: {title: 'Bewertungsmail', type: 'review_widget'}, usecase: 'send_mail'}
                showLoading.value = true
                try {
                  await root.$store.dispatch('leads/edit', { id: leadId, body: data })
                  refreshData()
                } finally {
                  showLoading.value = false
                }
              } else if (confirmation) {
                alert('Lead ID nicht gefunden! Bitte Seller Household.vue mit hh_id: '+props.id+' der IT melden')
              }
            }
          }
        })
        // actions.push(
        //   {
        //     title: 'Archivieren',
        //     icon: 'archive',
        //     tooltip: 'Haushalt archivieren',
        //     onClick: () => editModal.value.show()
        //   },
        //   {
        //     title: 'Pausieren',
        //     icon: 'pause',
        //     tooltip: 'Haushalt pausieren',
        //     onClick: () => editModal.value.show()
        //   }
        // )
        return actions
      })

      const onHouseholdUpdated = () => {
        editModal.value.hide()
        refreshData()
      }

      const onPostingCreated = () => {
        newPostingModal.value.hide()
        refreshData()
      }

      const submitTicket = () => {
        newTicketModal.value.hide()
        refreshData()
      }

      const updateHousehold = async (data: {[key: string]: any}) => {
        await root.$store.dispatch('households/edit', { id: props.id, body: { attributes: data } })
        refreshData()
      }

      return {
        showLoading,
        household,
        updateHousehold,
        posting,
        careReceivers,
        lead,
        contracts,
        tickets,
        title,
        shortDate,
        titleActions,
        refreshData,
        editModal,
        newPostingModal,
        onPostingCreated,
        newTicketModal,
        submitTicket,
        householdPosting,
        onHouseholdUpdated,
        careReceiverForm,
        resourceUrl,
        fullName,
        ticketIds,
        colsXl,
        contactAddress
      }
    }
  })
