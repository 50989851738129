
  import { defineComponent, ref, computed, getCurrentInstance } from "vue"
  import { fullName, fileSize, resourceUrl } from "@/utils/dataExtractors"
  import { shortDate } from "@/utils/helper"
  import { FileMetaData as FMDModel } from "@/models/file_meta_datas";
  import { CrPage, CrPages } from "@/models/care_receivers";
  import { ResourceObject } from '@/models/jsonapi'
  import IconsBarCareReceiver from "@/components/shared/IconsBarCareReceiver.vue";
  import CareReceiverProgressBar from "@/components/shared/CareReceiverProgressBar.vue";
  import FileMetaData from "@/components/shared/FileMetaData.vue";
  import IconsBarCareGiverRequirements from "@/components/shared/IconsBarCareGiverRequirements.vue";

  export default defineComponent({
    name: 'CrPanel',
    components: {
      IconsBarCareReceiver,
      CareReceiverProgressBar,
      IconsBarCareGiverRequirements,
      FileMetaData,
    },
    props: {
      careReceiver: {
        type: Object,
        required: true
      }
    },
    setup(props) {
      const root = getCurrentInstance().proxy
      const careReceiverForm = computed<CrPage[]>(() => root.$store.state.care_receivers.pages ?? [])
      root.$store.dispatch('care_receivers/get_pages')

      const displayFile = (fileMetaData: ResourceObject<string, FMDModel>) => {
        root.$store.dispatch('file_meta_datas/display_file',{fmd: fileMetaData})
      }

      const files = computed(() => props.careReceiver?.attributes?.file_meta_datas ?? [])

      return {
        fullName,
        resourceUrl,
        careReceiverForm,
        fileSize,
        displayFile,
        shortDate,
        files
      }
    }
  })
