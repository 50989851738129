
  import { defineComponent, reactive, ref, computed, getCurrentInstance } from "vue"
  import FormStepper from "@/components/shared/FormStepper.vue"
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"
  import { stringSort } from "@/utils/helper"
  import moment from "moment"
  import { prompt } from '@/utils/interactionModals'


  interface Form {
    posting: {
      id?: string,
      attributes: {
        body: string,
        comitted: boolean,
        arrivalDate: string,
      }
    },
    agencies: any[],
    nationalities: string[],
    startAt?: string
  }

  export default defineComponent({
    name: "PostingFormEdit",
    components: {
      FormStepper,
      SchemaForm
    },
    props: {
      householdId: {
        type: String,
        required: true
      },
      postingData: {
        type: Object,
        required: true,
      },
      editMode: {
        type: Boolean,
        default: false
      },
    },
    setup(props, { emit }) {
      const root = getCurrentInstance().proxy
      const showLoading = ref(false)
      const maxAgencies = 5
      const form = ref<Form>({
        posting: props.postingData.posting,
        agencies: props.postingData.agencies.slice(0,4),
        nationalities: props.postingData.available_nationalities,
        //startAt: props.postingData.startAt || new Date().toISOString()
      })
      if (form.value.posting.attributes.arrivalDate) {
        form.value.posting.attributes.arrivalDate = form.value.posting.attributes.arrivalDate.split('T')[0]
      }

      const agencySelectOptions = computed(() => {
        if (form.value.nationalities) {
          const ags = props.postingData.agencies
            .filter(agency => agency.nationalities
              .some(nat => form.value.nationalities.includes(nat)))
          return stringSort(ags, (i)=>i.name.toLowerCase())
        } else { return [] }
      })

      const formSchema = {
        $schema: "https://json-schema.org/draft/2019-09/schema",
        $id: "https://crm.pflegehilfe-senioren.de/posting-model.json",
        type: "object",
        properties: {
          posting: {
            type: "object",
            title: "Posting",
            properties: {
              attributes: {
                type: "object",
                title: "Attributes",
                properties: {
                  comitted: { type: "boolean", title: "Abschlusssicher" },
                  body: { type: "string", title: "Ausschreibungstext an die Agenturen (Eine Kurzübersicht)" },
                  arrivalDate: { type: "string", title: "Anreisedatum", format: "date" },
                }
              }
            }
          },
          nationalities: {
            type: "array",
            title: "Nationalitäten",
            items: {
              type: "string"
            }
          },
          agencies: {
            type: "array",
            title: "Agenturen",
            items: {
              type: "string"
            }
          },
          // startAt: {
          //   type: "string",
          //   title: "Posting sichtbar ab"
          // }
        },
        // required: ["start_at", "posting", "nationalities", "agencies"]
        required: ["posting", "nationalities", "agencies"]
      }

      const uiSchema = computed(() => {
        const base = {
          $schema: "https://json-schema.org/draft/2019-09/schema",
          $id: "https://crm.pflegehilfe-senioren.de/posting-view.json",
          $vocabulary: {
            "https://json-schema.org/draft/2019-09/vocab/core": true,
            "https://crm.pflegehilfe-senioren.de/ui-vocab": true
          },
          "ui:type": "form",
          "ui:widget": "b-row",
          type: "object",
          properties: {}
        }
        const nationalities = {
          "ui:type": "collection",
          "ui:widget": "v-select",
          "ui:options": {
            multiple: true,
            options: props.postingData.available_nationalities
          },
          "ui:label": "Gewünschte Sprachen",
          $ref: "https://crm.pflegehilfe-senioren.de/posting-model.json#/properties/nationalities",
          items: {
            type: "string",
            $ref: "https://crm.pflegehilfe-senioren.de/posting-model.json#/properties/nationalities/items"
          }
        }
        const agencies = {
          "ui:type": "collection",
          "ui:widget": "v-select",
          "ui:options": {
            multiple: true,
            options: agencySelectOptions.value,
            label: "name",
            selectable: ()=> {return form.value.agencies.length < maxAgencies}
          },
          "ui:label": `Agenturen (max. ${maxAgencies})`,
          $ref: "https://crm.pflegehilfe-senioren.de/posting-model.json#/properties/agencies",
          items: {
            type: "string",
            $ref: "https://crm.pflegehilfe-senioren.de/posting-model.json#/properties/agencies/items"
          }
        }
        // startAt: {
        //   "ui:widget": "datetime",
        //   "ui:options": {
        //     type: "datetime",
        //     'minute-step': 10
        //   },
        //   $ref: "https://crm.pflegehilfe-senioren.de/posting-model.json#/properties/startAt"
        // },
        const posting = {
          $ref: "https://crm.pflegehilfe-senioren.de/posting-model.json#/properties/posting",
          type: "object",
          "ui:type": "group",
          "ui:label": "",
          "ui:widget": "div",
          properties: {
            attributes: {
              type: "object",
              $ref: "https://crm.pflegehilfe-senioren.de/posting-model.json#/properties/posting/properties/attributes",
              "ui:type": "group",
              "ui:label": "",
              "ui:widget": "b-row",
              properties: {
                body: {
                  "ui:widget": "html-element",
                  $ref: "https://crm.pflegehilfe-senioren.de/posting-model.json#/properties/posting/properties/attributes/properties/body"
                },
                comitted: {
                  "ui:type": "checkbox",
                  "ui:classNames": ["col-6"],
                  $ref: "https://crm.pflegehilfe-senioren.de/posting-model.json#/properties/posting/properties/attributes/properties/comitted"
                },
                arrivalDate: {
                  $ref: "https://crm.pflegehilfe-senioren.de/posting-model.json#/properties/posting/properties/attributes/properties/arrivalDate",
                  "ui:label": "Anreisedatum",
                  "ui:classNames": ["col-6"],
                  "ui:widget": "b-input",
                  "ui:options": { type: "date" },
                },
              }
            }
          }
        }
        if (props.editMode) {
          base.properties = { posting }
        } else {
          base.properties = { nationalities, agencies, posting }
        }
        return base
      })

      const updatedForm = (formValue) => {
        return form.value = formValue
      }

      const submitForm = async () => {
        let formValue = { ...form.value }
        formValue.agencies = formValue.agencies.map(agency => agency.id)
        formValue.posting = {
          attributes: formValue.posting.attributes
        }
        if (props.editMode) {
          const postingId = props.postingData.posting.id
          if (postingId) {
            await root.$store.dispatch('postings/edit', { id: postingId, body: formValue.posting })
            emit('posting-updated')
          } else {
            alert('Stellenausschreibung ohne id kann nicht aktualisiert werden.')
          }
        // } else {
        //   if (formValue.posting.attributes.arrivalDate === null) {
        //     prompt(root, 'Das Anreisedatum ist erforderlich!')
        //   } else {
        //     showLoading.value = true
        //     await root.$store.dispatch('households/create_new_posting', { id: props.householdId, body: formValue })
        //     showLoading.value = false
        //     emit('posting-updated')
        //   }
        }
      }

      const removeAllSelected = () => {
        form.value.nationalities = []
        form.value.agencies = []
      }

      const removeSelectedAgencies = () => {
        form.value.agencies = []
      }

      return {
        form,
        formSchema,
        uiSchema,
        updatedForm,
        submitForm,
        removeAllSelected,
        removeSelectedAgencies,
        showLoading
      }
    }
  })
