
  import { defineComponent } from "vue"

  interface Icon {
    icon: string,
    prefix: string,
    class: string,
    title: string
  }

  interface StackIcons {
    stack: Icon[]
  }

  interface Props {
    icons: StackIcons[]
  }

  export default defineComponent({
    name: 'IconsBar',
    props: {
      icons: {
        type: Array,
        default: () => []
      }
    },
    setup(prop: Props) {
      return {}
    }
  })
