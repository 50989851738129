
  import { defineComponent, computed } from "vue"
  import { Household } from "@/models/households"
  import { Address } from "@/models/addresses"
  import { shortDate } from "@/utils/helper"

  interface Props { household: Household, contactAddress: any}

  export default defineComponent({
    name: 'HouseholdKeyData',
    props: {
      household: {
        type: Object,
        required: true
      },
      contactAddress: {
        type: Object
      }
    },
    setup(props: Props) {
      const addressType = computed(() => {
        if (!props.contactAddress) return 'Unbekannt'
        const tp = props.contactAddress?.attributes?.addressableType
        if (tp === 'Lead') return 'Lead'
        if (tp === 'CareReceiver') return 'PB'
      })
      return {
        shortDate,
        addressType
      }
    }
  })
