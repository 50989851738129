
  import { defineComponent, reactive, computed, unref } from "vue"
  import { useModelWrapper } from "@/utils/modelWrapper";
  import { Phone } from "@/models/phones"

  export default defineComponent({
    props: {
      default: {
        type: Boolean,
        default: false
      },
      machineNumber: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      number: {
        type: String,
        default: ''
      }
    },
    setup(props: Phone, { emit }) {
      const phone = reactive({
        number: useModelWrapper(props, emit, 'number'),
        name: useModelWrapper(props, emit, 'name'),
        default: useModelWrapper(props, emit, 'default'),
      })

      return {
        phone
      }
    }
  })
