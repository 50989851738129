export const confirm = async (root: any, message: string) => {
  const decision = await root.$bvModal.msgBoxConfirm(message, {
    title: 'Bestätigung',
    size: 'md',
    buttonSize: 'md',
    okVariant: 'danger',
    okTitle: 'Ja',
    cancelTitle: 'Nein',
    footerClass: 'p-2',
    hideHeaderClose: false,
    centered: true,
    bodyClass: 'ws-pre-wrap'
  })
  return decision
}

export const prompt = async (root: any, message: string) => {
  root.$bvModal.msgBoxOk(message, {
    // title: 'Bestätigung',
    size: 'md',
    buttonSize: 'md',
    // okVariant: 'danger',
    okTitle: 'Ok',
    // cancelTitle: 'Nein',
    footerClass: 'p-2',
    hideHeaderClose: false,
    centered: true
  })
}