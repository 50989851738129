import { render, staticRenderFns } from "./PostingFormEdit.vue?vue&type=template&id=34c20270&scoped=true&lang=pug"
import script from "./PostingFormEdit.vue?vue&type=script&lang=ts"
export * from "./PostingFormEdit.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34c20270",
  null
  
)

export default component.exports