import { render, staticRenderFns } from "./FileMetaData.vue?vue&type=template&id=c16e8fb6&lang=pug"
import script from "./FileMetaData.vue?vue&type=script&lang=ts"
export * from "./FileMetaData.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports