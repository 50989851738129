
  import { defineComponent, ref, computed, getCurrentInstance } from "vue"
  import { fullName, fileSize } from "@/utils/dataExtractors";
  // import LoadingOverlay from "@/components/shared/LoadingOverlay.vue";
  import { FileMetaData as FMDModel } from "@/models/file_meta_datas";
  // import { ResourceObject, RelationshipsWithData, ResourceIdentifierObject, SingleResourceDoc } from "@/models/jsonapi";
  import { ResourceObject } from "@/models/jsonapi";
  import { confirm, prompt } from '@/utils/interactionModals'
  import Uppy from "@/components/shared/Uppy.vue"
  import moment from 'moment'


  interface Props {
    files: ResourceObject<string, FMDModel>[],
    headerText: string,
    searchBtnText: string,
    mailable: boolean,
    multiple: boolean,
  }

  export default defineComponent({
    name: 'FileMetaData',
    components: {
      Uppy,
    },
    props: {
      files: {
        type: Array,
        required: true,
      },
      headerText: {
        type: String,
        default: 'Datei Anhänge',
      },
      searchBtnText: {
        type: String,
        default: 'Hochladen',
      },
      mailable: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: true
      },
      readOnly: {
        type: Boolean,
        default: false
      }
    },
    setup(props: Props, { emit }) {
      const root = getCurrentInstance().proxy
      const fileEditModal = ref()
      const editId = ref('')
      const editFilename = ref('')
      const mailIncluded = ref(true)
      // const showLoading = ref(false)

      const formatDate = (date: string) => moment(date).locale('de').format('lll')

      const processUppyResult = (result) => {
        const fileMetaDataIds = result.successful
          .map(suc => suc.response.body.data[0].id)
        let data
        if (props.multiple) {
          data = {attributes: {fileMetaDataIds: fileMetaDataIds}}
        } else {
          data = {attributes: {fileMetaDataId: fileMetaDataIds[0]}}
        }
        emit('uploaded', data)
      }

      const displayFile = (fileMetaData: ResourceObject<string, FMDModel>, shouldDownload: boolean = false) => {
        root.$store.dispatch('file_meta_datas/display_file', { fmd: fileMetaData, shouldDownload: shouldDownload })
      }

      const toggleFile = async (file, toggleAttributeName) => {
        const data = { attributes: { [toggleAttributeName]: !file.attributes[toggleAttributeName] }}
        await root.$store.dispatch('file_meta_datas/edit', { id: file.id, body: data })
        emit('updated')
      }

      const updateFile = async () => {
        // const attData = data.attributes ? data : {attributes: data}
        const attData = {attributes: {
          filename: editFilename.value,
          mailIncluded: mailIncluded.value
        }}
        await root.$store.dispatch('file_meta_datas/edit', { id: editId.value, body: attData })
        emit('updated')
      }

      const deleteFile = async (file) => {
        const msg = 'Die Datei wird gelöscht! Fortfahren?'
        const confirmation = await confirm(root, msg)
        if (confirmation) {
          await root.$store.dispatch('file_meta_datas/delete', file.id)
          emit('updated')
        }
      }

      const showFileEditModal = (file: any) => {
        editId.value = file.id
        editFilename.value = file.attributes.filename
        mailIncluded.value = file.attributes.mailIncluded
        fileEditModal.value.show()
      }

      const showUpload = computed(() => props.multiple || props.files.length < 1)

      return {
        editFilename,
        mailIncluded,
        displayFile,
        toggleFile,
        formatDate,
        updateFile,
        deleteFile,
        showFileEditModal,
        processUppyResult,
        fileEditModal,
        showUpload,
        fileSize
      }
    }
  })
