
  import { defineComponent, ref, computed } from "vue"
  import { CrPage, Field } from "@/models/care_receivers"
  import { getNonRequiredFields, getRequiredFields, getMissingFields } from "@/utils/careReceiverHelper"

  interface Props {
    careReceiver: any,
    careReceiverForm: CrPage[]
  }

  export default defineComponent({
    name: "CareReceiverProgressBar",
    props: {
      careReceiver: {
        type: Object,
        required: true
      },
      careReceiverForm: {
        type: Array,
        required: true
      }
    },
    setup(props: Props) {
      const allFields = computed<Field[]>(() => props.careReceiverForm.flatMap(page => page.fields))

      const requiredQuestions = computed<number>(() => {
        return getRequiredFields(allFields.value, props.careReceiver.attributes).length
      })

      const nonRequiredQuestions = computed<number>(() => {
        return getNonRequiredFields(allFields.value, props.careReceiver.attributes).length
      })

      const requiredAnswered = computed<number>(() => {
        let req = 0
        getRequiredFields(allFields.value, props.careReceiver.attributes).forEach(field => {
          req += props.careReceiver.attributes[field.key] ? 1 : 0
        })
        return req
      })

      const nonRequiredAnswered = computed<number>(() => {
        let nonReq = 0
        getNonRequiredFields(allFields.value, props.careReceiver.attributes).forEach(field => {
          nonReq += props.careReceiver.attributes[field.key] ? 1 : 0
        })
        return nonReq
      })

      return {
        requiredQuestions,
        requiredAnswered,
        nonRequiredQuestions,
        nonRequiredAnswered
      }
    }
  })
