
  import { defineComponent, computed } from "vue"
  import { ResourceObject } from "@/models/jsonapi"
  import { Address } from "@/models/addresses";
  import { fullSalute } from "@/utils/dataExtractors";

  interface Props {
    address: ResourceObject<string, Address>,
    contactType?: string,
    url?: string,
    readOnly?: boolean,
  }

  export default defineComponent({
    name: 'Address',
    props: {
      address: {
        type: Object,
        required: true
      },
      contactType: {
        type: String,
      },
      url: {
        type: String
      },
      readOnly: {
        type: Boolean,
        default: false
      }
    },
    setup(props: Props) {
      const defaultPhone = computed(() => {
        const phones = props.address.attributes?.phones
        if (!phones || phones.length === 0) return '--'
        const defaultPhones = phones.filter(p => p.default)
        return defaultPhones.length > 0 ? defaultPhones[0].number : phones[0].number
      })

      const defaultEmail = computed(() => {
        const emails = props.address.attributes?.emails
        if (!emails || emails.length === 0) return '--'
        const defaultEmails = emails?.filter(e => e.default)
        return defaultEmails.length > 0 ? defaultEmails[0].email : emails[0].email
      })

      const location = (cr) => {
        const zipCode = cr.zipCode ?? ''
        const city = cr.city ?? ''
        const location = `${zipCode} ${city}`.trim()
        return (location.length > 0) ? location : '--'
      }

      const street = (cr) => {
        const streetName = cr.street ?? ''
        const houseNumber = cr.houseNumber ?? ''
        const street = `${streetName} ${houseNumber}`.trim()
        return (street.length > 0) ? street : '--'
      }

      return {
        defaultPhone,
        defaultEmail,
        street,
        location,
        fullSalute
      }
    }
  })
