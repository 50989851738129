
  import { defineComponent } from "vue"
  import IconsBar from "./IconsBar.vue"
  import { ResourceObject } from "@/models/jsonapi";
  import { CareReceiver } from "@/models/care_receivers";

  interface Props {
    careReceiver: ResourceObject<string, CareReceiver>
  }

  export default defineComponent({
    name: 'IconsBarCareGiverRequirements',
    components: {
      IconsBar
    },
    props: {
      careReceiver: {
        type: Object,
        required: true
      }
    },
    setup(props: Props) {
      const sortedLanguages = () => {
        const sortedLanguageLevels = ["sehr gut","gut","befriedigend","ausreichend","sehr gering","keine"]
        const tmp = props.careReceiver.attributes?.ctLanguage
        if (tmp) {
          return tmp.filter(a=>typeof(a)==="string").sort((a,b) => {
            return sortedLanguageLevels.indexOf(b)-sortedLanguageLevels.indexOf(a)
          })
        } else {
          return ['']
        }
      }
      const createIcons = () => {
        const icons: any[] = []
        if (props.careReceiver.attributes?.ctProfile === 'Mann') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'mars', class: 'text-muted  fa-2x', title: 'Mann gewünscht' }] })
        } else if (props.careReceiver.attributes?.ctProfile === 'Frau') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'venus', class: 'text-muted  fa-2x', title: 'Frau gewünscht' }] })
        } else if (props.careReceiver.attributes?.ctProfile === 'egal') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'transgender', class: 'text-muted', title: 'Geschlecht egal' }] })
        } else {
          icons.push({ stack: [{ prefix: 'fas', icon: 'question', class: 'lighter', title: 'Geschlecht' }] })
        }
        const lowestLanguage = sortedLanguages()[0]
        const lngList = 'gewünschte DK: '+sortedLanguages().join(', ')
        if (lowestLanguage === 'keine') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'signal-alt-slash', class: 'text-muted', title: lngList }] })
        } else if (lowestLanguage === 'sehr gering') {
          icons.push({ stack: [{ prefix: 'fad', icon: 'signal-alt', class: 'lighter', title: lngList }] })
        } else if (lowestLanguage === 'ausreichend') {
          icons.push({ stack: [{ prefix: 'fad', icon: 'signal-alt-1', class: 'text-muted', title: lngList }] })
        } else if (lowestLanguage === 'befriedigend') {
          icons.push({ stack: [{ prefix: 'fad', icon: 'signal-alt-2', class: 'text-muted', title: lngList }] })
        } else if (lowestLanguage === 'gut') {
          icons.push({ stack: [{ prefix: 'fad', icon: 'signal-alt-3', class: 'text-muted', title: lngList }] })
        } else if (lowestLanguage === 'sehr gut') {
          icons.push({ stack: [{ prefix: 'fad', icon: 'signal-alt', class: 'text-muted', title: lngList }] })
        } else {
          icons.push({ stack: [{ prefix: 'fas', icon: 'question', class: 'lighter', title: 'Sprachkenntnisse' }] })
        }

        if (props.careReceiver.attributes?.ctDriver === 'Ja') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'car-side', class: 'text-muted', title: 'Führerschein erforderlich' }] })
        } else if (props.careReceiver.attributes?.ctDriver === 'wünschenswert') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'car-side', class: 'text-muted', title: 'Führerschein wünschenswert' }] })
        } else if (props.careReceiver.attributes?.ctDriver === 'nicht relevant') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'car-side', class: 'text-muted', title: 'Führerschein nicht relevant' }, { prefix: 'far', icon: 'ban', class: 'text-muted', title: 'Führerschein' }] })
        } else {
          icons.push({ stack: [{ prefix: 'fas', icon: 'question', class: 'lighter', title: 'Führerschein' }] })
        }

        if (props.careReceiver.attributes?.ctSmoker === 'Ja') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'smoking', class: 'text-muted', title: 'Rauchen gestattet' }] })
        } else if (props.careReceiver.attributes?.ctSmoker === 'nur im Freien') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'smoking', class: 'text-muted', title: 'Rauchen nur im Freien' }] })
        } else if (props.careReceiver.attributes?.ctSmoker === 'Nein') {
          icons.push({ stack: [{ prefix: 'fas', icon: 'smoking', class: 'text-muted', title: 'Rauchen nicht gestattet' }, { prefix: 'far', icon: 'ban', class: 'text-muted', title: 'Rauchen nicht gestattet' }] })
        } else {
          icons.push({ stack: [{ prefix: 'fas', icon: 'question', class: 'lighter', title: 'Rauchen' }] })
        }

        return icons
      }

      return {
        createIcons
      }
    }
  })
